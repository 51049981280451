.App {
    background-color: #FFFFFF;
    background-image: radial-gradient(#C0C0CF 1px, transparent 0);
    background-size: 40px 40px;
    background-position: 12px 12px;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
}

img {
    max-width: 100%;
}

.page-content {
    padding-top: 200px;
}

