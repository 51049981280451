/* general */
.content {
    padding-top: 200px;
    width: 70%;
    margin: auto;
}
/* end of general */


/* home */
.heroContainer {
    width: 70%;
    margin: auto;
    align-items: center;
    
}

.heroContainer h1 {
    font-family: "Paytone One", sans-serif;
    font-size: 140px;
    color: #000;
    width: 1000px;
    letter-spacing: 10px;
}

.heroContainer p {
    font-family: "Space Grotesk", sans-serif;
    font-size: 20px;
    text-align: center;
}

.heroContainer span.smallText {
    font-family: "Grape Nuts", cursive;
    font-size: 32px;
    color: #218E2E;
    letter-spacing: 0px;
}

.heroContainer .nameIntroduction {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding-top: 12px;
    gap: 8px;
} 

.heroContainer .nameIntroduction p {
    position: relative;
    top: 9px; 
}

.heroContainer .nameIntroduction img {
    width: 36px;
    height: 36px;
    transition: width 1s, height 1s;
}

.heroContainer .nameIntroduction img:hover {
    width: 60px;
    height: 60px;
    transition: width 1s, height 1s;
}

.heroFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 80px 0;
}

.socials {
    display: flex;
    gap: 20px; /* adjust spacing between social links */
}

.socials a, 
.emailBtn {
    font-family: "Urbanist", sans-serif;
    padding-right: 32px;
    text-decoration: none;
    color: #000;
    font-size: 18px;
}

.emailBtn span {
    text-decoration: underline;
}

.socials a:hover {
    color: #218E2E;
    text-decoration: underline;
}

.emailBtn {
    position: relative;
    perspective: 1000px;
    display: inline-block;
}

.emailBtn span {
    position: absolute;
    left: 0;
    width: 100%;
    backface-visibility: hidden;
    transition: transform 0.6s;
    white-space: nowrap; /* prevents text from wrapping */
}

.emailBtn .hover-text {
    transform: rotateX(180deg);
}

.emailBtn .default-text {
    transform: rotateX(0);
}

.emailBtn:hover .hover-text {
    transform: rotateX(0);
}

.emailBtn:hover .default-text {
    transform: rotateX(-180deg);
}
/* end of home */


/* about */
.introduction {
    text-align: center;
    font-family: "Space Grotesk", sans-serif;
}
.introduction p {
    color: #ABACB9;
    font-size: 16px;
    font-weight: 500;
}
.introduction h1 {
    width: 400px;
    margin: auto;
    color: #000;
    font-weight: 600;
    letter-spacing: 1px;
}

.about h2 {
    color: #000;
    font-family: "Urbanist", sans-serif;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 1.5px;
}

span.nameSound {
    font-family: "Space Grotesk", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #ABACB9 !important;
}

span.nameSound img {
    width: 24px;
}

.about img.aboutImg {
    height: 700px;
}

.about span,
.about a {
    color: #218E2E;
    font-weight: 600;
}

.about a:hover {
    text-decoration: none;
    color: #53df61;
}

.doings {
    padding: 100px;
}

.doings h3 {
    font-family: "Space Grotesk", sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 3rem;
}

.doings p {
    font-size: 28px;
    color: #218E2E;
    padding-left: 8px;
    padding-top: 32px;
    font-family: "Grape Nuts", cursive;
}

.fun img {
    padding-bottom: 120px;
}
/* end of about */



/* Project */
.titleHeading {
    position: relative;
}

.selected, .projects {
    background-color: #C4FFAF;
    border: 2.5px solid #218E2E;
    border-radius: 4px; 
    padding: 10px;
    color: black;
    font-weight: bold;
    font-family: "Space Grotesk", sans-serif;
    font-weight: 700;
    font-size: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 40px;
    width: 284px;
    height: 85px;
}

.selected {
    position: absolute;
    top: 10px;
    left: 10px;
    /* width: 120px; */
}

.projects {
    position: absolute;
    top: 80px;
    left: 50px;
    /* width: 100px; */
    transform: rotate(-10deg);
}

.projectContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 250px 0 100px 0;
    position: relative;
}

.projectContainer:hover .projectCard {
    filter: blur(7px) grayscale(100%);
}

.projectCard:hover {
    filter: blur(0) grayscale(0) !important;
    transform: scale(1.02);
    z-index: 1;
}

.projectCard {
    width: calc(50% - 20px);
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 40px;
    position: relative;
    transition: transform 0.3s ease, filter 0.3s ease;
}

.project {
    text-decoration: none;
    display: block;
}

.projectImage {
    background-color: #FFF;
    border: 1px solid #E7E8EE;
    border-radius: 20px;
    box-shadow: 0px 4px 10px 5px #EAEEF7;
    transition: transform 0.3s ease;
}

.projectContent {
    transition: transform 0.3s ease;
}

.project img {
    width: 100%;
    height: 400px;
    padding: 12px;
}

.project h2 {
    font-family: "Space Grotesk", sans-serif;
    font-size: 28px;
    line-height: 48px;
    font-weight: 700;
    color: #000;
    padding-top: 16px;
}

.projectTag {
    background-color: #F3F4F6;
    color: #ABACB9;
    border: 1px solid #E7E8EE;
    border-radius: 100px;
    padding: 2px 14px;
    margin: 10px 8px 0 0;
    display: inline-block; 
    font-size: 16px;
}

.title {
    display: flex;
    justify-content: end;
}

.title img {
    width: 60px;
}

.title p {
    font-size: 24px;
    color: #218E2E;
    padding-left: 8px;
    font-family: "Grape Nuts", cursive;
}
/* end of projects */



/* case studies */
.case-study {
    width: 60%;
    margin: auto;
    position: relative;
    padding-top: 200px;
}

.pageContent h1,
.pageContent h5 {
    font-family: "Space Grotesk", sans-serif;
}

.pageContent h1 {
    font-size: 50px;
    font-weight: 700;
    line-height: 1.2;
    padding-bottom: 16px;
}

.pageContent h2 {
    font-size: 18px;
    font-weight: 600;
    color: #218E2E;
}

.pageContent h3 {
    color: #218E2E;
    font-weight: 500;
    font-size: 32px;
}

.pageContent h3 span {
    font-size: 60px;
    font-family: "Space Grotesk", sans-serif;
}

.pageContent h4 {
    font-size: 20px;
    font-weight: bolder;
}

.pageContent h5 {
    font-size: 18px;
    font-weight: 500;
    color: #218E2E;
}

.pageContent h6 {
    font-size: 16px;
    font-weight: 500;
    color: #ABACB9;
}

.pageContent p {
    font-size: 16px;
    line-height: 1.6;
}

.pageContent a {
    text-decoration: underline;
    color: #218E2E;
}

.pageContent a:hover {
    text-decoration: none;
    color: #000000;
}

img.coverImage {
    padding-top: 16px;
    padding-bottom: 60px;
}

.summary p {
    max-width: 500px;
}

.role p {
    max-width: 400px;
}

.contentCard {
    color: #8A8B9D;
    background-color: #F3F4F6;
    border: 1px solid #E7E8EE;
    border-radius: 5px;
    padding: 10px 16px;
}

.quote {
    margin-top: 1rem;
    margin-bottom: 3rem;
    border-width: 0 0 0 4px;
    border-style: solid;
    padding-left: 2rem;
    padding-right: 2rem;
    font-style: italic;
    font-weight: 300;
    line-height: 2.5rem;
}

ul li {
    padding-bottom: 18px;
}

.videos video {
    width: 100%;
    height: auto;
    padding-bottom: 60px;
}
/* end of case studies */